import React from "react";
import styles from "./aboutUs.module.scss";
import { Sequel45, Sequel65, Sequel85 } from "../Typography";
import { Star, Whop } from "../../constants/Svg";

export default function AboutUs() {
  return (
    <div className={styles.aboutUs}>
      <div className={styles.aboutUs__text}>
        <Sequel85 className={styles.aboutUs__text__topText}>About Us</Sequel85>
      </div>
      <Sequel45 className={styles.aboutUs__subText}>
        After supplying bulk streetwear apparel to several well known sneaker
        stores and private bulk buyers, we wanted to expand our clientele to a
        select amount of people. For this reason, we created Preme FNF.{" "}
        <Sequel65 className={styles.aboutUs__subText__sequel65}>
          Our goal in Preme FNF is to help our members make money by reselling
          the items
        </Sequel65>{" "}
        that we provide to them for wholesale prices.
      </Sequel45>
      <div className={styles.aboutUs__whop__container}>
        <Whop
          onClick={() => {
            window.open("https://whop.com/premefnf/", "_blank");
          }}
          className={styles.aboutUs__whop}
        />
        <div>
          <Sequel85 className={styles.aboutUs__whop__text}>5 Stars</Sequel85>
          {new Array(5).fill(0).map((_, i) => (
            <Star className={styles.aboutUs__whop__star} key={i} />
          ))}
        </div>
      </div>
    </div>
  );
}
