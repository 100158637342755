import React from "react";
import { HurmeGeometricSans3, Sequel85 } from "../Typography";

import styles from "./heroLeft.module.scss";
import Stars from "../Stars";
import { DotsGrid, StripedCircle } from "../../constants/Svg";
import SlideUp, { SlideLeft } from "../Anmations";
import PcOnly from "../PcOnly";
import MobileOnly from "../MobileOnly";

export default function HeroLeft({ loading }) {
  return (
    <div className={styles.heroLeft}>
      <SlideUp>
        <Sequel85 className={styles.primaryText}>The Ultimate</Sequel85>
        <Sequel85 className={styles.secondaryText}>Backdoor </Sequel85>
        <Sequel85
          className={`${styles.secondaryText2} ${styles.secondaryText}`}
        >
          Group
        </Sequel85>
      </SlideUp>
      <PcOnly>
        <StripedCircle customClass={styles.stripedCircle} />
        <DotsGrid customClass={styles.dotsGrid} />
      </PcOnly>
      <MobileOnly>
        <img
          className={styles.mobRight}
          src={require("../../assets/images/mobRight.webp")}
          alt="striped-circle"
        />
      </MobileOnly>
      <SlideLeft>
        <div className={styles.btns}>
          <Button
            onClick={() => {
              window.open("https://whop.com/premefnf/", "_blank");
            }}
          >
            <HurmeGeometricSans3>Buy Now</HurmeGeometricSans3>
          </Button>
          <Button
            data-type="more-info"
            onClick={() => {
              window.open("#features", "_self");
            }}
          >
            <HurmeGeometricSans3>More Info</HurmeGeometricSans3>
          </Button>
          <PcOnly>
            <Stars customClass={styles.heroStars} />
          </PcOnly>
        </div>
      </SlideLeft>
    </div>
  );
}

const Button = ({ children, ...props }) => {
  return (
    <button className={styles.button} {...props}>
      {children}
    </button>
  );
};
