import React from "react";
import styles from "./footer.module.scss";
import { PremeTextBlack } from "../../App";
import { Star, WhopBlack } from "../../constants/Svg";
import { Sequel85 } from "../Typography";

export default function Footer() {
  return (
    <div className={styles.footer}>
      <div className={styles.solidBg} />
      <img
        className={styles.footer__bg}
        src={require("../../assets/images/footerbg.webp")}
        alt="footer"
      />
      <div className={styles.footer__left}>
        <div className={styles.footer__logoContainer}>
          <img
            className={styles.footer__logo}
            src={require("../../assets/images/logo.webp")}
            alt="logo"
          />
        </div>

        <PremeTextBlack />
      </div>

      <div className={styles.footer__right}>
        <span
          onClick={() => {
            window.open("https://whop.com/premefnf/", "_blank");
          }}
        >
          <WhopBlack />
        </span>

        <div className={styles.footer__right__text}>
          <Sequel85>5 Stars</Sequel85>
          {Array(5)
            .fill(0)
            .map((_, i) => (
              <Star className={styles.footer__right__text__star} key={i} />
            ))}
        </div>
      </div>
    </div>
  );
}
