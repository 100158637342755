import React, { useRef } from "react";
import styles from "./aboutUsMobileImages.module.scss";
import { motion, useScroll, useTransform } from "framer-motion";

export default function AboutUsMobileImages() {
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    smooth: true,
    offset: ["start end", "400px end"],
  });
  const opacity = useTransform(scrollYProgress, [0, 1], [0.25, 1]);
  const y1 = useTransform(scrollYProgress, [0, 1], [150, 0]);
  const y2 = useTransform(scrollYProgress, [0, 1], [200, 0]);
  const y3 = useTransform(scrollYProgress, [0, 1], [250, 0]);

  return (
    <div className={styles.images} ref={ref}>
      <motion.img
        style={{
          opacity,
          y: y1,
        }}
        transition={{
          ease: "linear",
          delay: 0.1,
        }}
        className={styles.images__desktop}
        src={require("../../assets/images/aboutMobile.webp")}
        alt="about"
      />
      <motion.img
        style={{
          willChange: "transform",
          opacity,
          y: y2,
        }}
        className={styles.images__desktop2}
        src={require("../../assets/images/aboutMobile2.webp")}
        alt="about"
      />
      <motion.img
        style={{
          opacity,
          y: y3,
        }}
        className={styles.images__desktop3}
        src={require("../../assets/images/aboutMobile3.webp")}
        alt="about"
      />
    </div>
  );
}
